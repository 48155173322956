<template>
    <el-card shadow="never" style="margin-top: 10px;">

        <div class="page-board">
            <div class="card-panel">

                <el-descriptions :column="4" border class="description-container">
                    <el-descriptions-item label-class-name="my-label" :rowspan="2" :width="140" :height="400"
                        label="交易成功订单" label-align="center" align="center">
                        <div class="value-container">
                            {{ summaryData?.success_orders ? summaryData.success_orders + '笔' : '0笔' }}
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item label-class-name="my-label" :rowspan="2" :width="140" label="交易成功金额"
                        label-align="center" align="center">
                        {{ summaryData?.success_amount ? summaryData.success_amount + '元' : '0元' }}
                    </el-descriptions-item>
                    <el-descriptions-item label-class-name="my-label" :rowspan="2" :width="140" label="退款订单"
                        label-align="center" align="center">
                        {{ summaryData?.refund_orders ? summaryData.refund_orders + '笔' : '0笔' }}
                    </el-descriptions-item>
                    <el-descriptions-item label-class-name="my-label" :rowspan="2" :width="140" label="退款金额"
                        label-align="center" align="center">
                        {{ summaryData?.refund_amount ? summaryData.refund_amount + '元' : '0元' }}
                    </el-descriptions-item>

                    <el-descriptions-item label-class-name="my-label" :rowspan="2" :width="140" :height="400"
                        label="订单总数" label-align="center" align="center">
                        <div class="value-container">
                            {{ summaryData?.total_orders ? summaryData.total_orders + '笔' : '0笔' }}
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item label-class-name="my-label" :rowspan="2" :width="140" label="手续费"
                        label-align="center" align="center">
                        {{ summaryData?.fee_amount ? summaryData.fee_amount + '元' : '0元' }}
                    </el-descriptions-item>
                    <el-descriptions-item label-class-name="my-label" :rowspan="2" :width="140" label="结算金额"
                        label-align="center" align="center">
                        {{ summaryData?.settlement_amount ? summaryData.settlement_amount + '元' : '0元' }}
                    </el-descriptions-item>
                    <el-descriptions-item label-class-name="my-label" :rowspan="2" :width="140" label=""
                        label-align="center" align="center"></el-descriptions-item>
                </el-descriptions>
            </div>
        </div>
    </el-card>

</template>

<script setup>
import { defineProps } from 'vue';
// 定义 Props 接收看板数据
const props = defineProps(['summaryData']);
console.log(props);

</script>

<style lang="less" scoped>
.card-container {
    margin-top: 30px;
}

.page-board {
    .card-panel {
        .description-container {
            .my-label {
                background: #cfcfcf46 !important;
            }

            .value-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 60px;
                margin: 0 auto;
                text-align: center;
            }
        }
    }
}
</style>